/** @format */

import React, { useRef } from "react";

import { useEffect, useState } from "react";

import DisplayMovieImage from "./DisplayMovieImage";

import * as OFFSET_ACTION from "./../Redux/live/actions";

import * as OFFSET_MOVIE_ACTION from "./../Redux/MoviesRedux/actions";

import * as OFFSET_SERIES_ACTION from "./../Redux/SeriesGenre/actions";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

import "swiper/css/scrollbar";

import "swiper/css/navigation";

import "swiper/css/pagination";

import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper/modules";

import "./MovieCatogory.css";

import * as LIVE_ACTION from "./../Redux/AllLiveDetails/actions";

import { useHistory } from "react-router-dom";

import { useDispatch, connect } from "react-redux";

import AdsComponent from "./ADS Component/AdsComponent";

import * as ALL_ASSET_ACTION from "./../Redux/AllLiveDetails/actions";

import MovieScreenDisplayMovieImage from "./MoviesScreens/MovieScreenDisplayMovieImage";

import SeriesPageDisplayImage from "./SeariesPages/SeriesPageDisplayImage";

import store from "../Redux/store";

import { getGenreDetailsBaedOnkeyDetailsSelector } from "../Redux/AllLiveDetails/selectors";

const MovieCatogory = ({
  VIDEO_ADS,

  value,

  genreAssetAssociation,

  genreIndex,

  USER_DETAILS,

  genreId,

  CURRENT_PLATFORM,

  ALL_GENRE_DATA,

  ASSETS_LENGTH,

  AUTHENTICATION,

  selectedCategory,

  genreCategory,

  DEVICE_TOKEN,

  CONFIGURATION_DAS,
}) => {
  const dispatch = useDispatch();

  const elementRef = useRef({});

  const [sate, setSate] = useState(null);

  const handleSlideChange = (swiper) => {
    setSate({ activeSlide2: swiper.activeIndex });
  };

  const stateData = store.getState();

  var genrealdetailsfromStore = getGenreDetailsBaedOnkeyDetailsSelector(
    stateData
  );

  // const getAssetsByGenreId = (genreId) => {

  //   return genrealdetailsfromStore[genreId]?.genreassetassociation || [];

  // };

  // // console.log("genreCategory" + JSON.stringify(genreCategory));

  // console.log(

  //   "genrealdetailsfromStore" + JSON.stringify(genrealdetailsfromStore)

  // );

  // const genreIdToFilter = "6"; // Change this to filter a different genre

  // const filteredAssets = getAssetsByGenreId(genreIdToFilter);

  // console.log("filteredAssets" + JSON.stringify(filteredAssets));

  let filteredAssets = genrealdetailsfromStore?.[`"${genreId}"`];

  // console.log("filteredAssets" + +JSON.stringify(genreId));

  console.log(
    "filteredAssets" +
      genreId +
      JSON.stringify(filteredAssets?.genreassetassociation?.length)
  );

  const [
    genreDetailsWithAssetAssociation,

    setGenreDetailsWithAssetAssociation,
  ] = useState(genreAssetAssociation);

  useEffect(() => {
    setGenreDetailsWithAssetAssociation(genreAssetAssociation);
  }, [genreAssetAssociation]);

  console.log("genreAssetAssociation" + JSON.stringify(genreAssetAssociation));

  useEffect(() => {
    // console.log(
    //   "genreAssetAssociationinwindo" +
    //     JSON.stringify(filteredAssets?.genreassetassociation?.length)
    // );

    if (
      selectedCategory == 1 &&
      sate != null &&
      filteredAssets?.genreassetassociation?.length <
        // filteredAssets?.genreassetassociation?.length <
        ASSETS_LENGTH[genreId]?.assetCount
    ) {
      handleOffsetData();
    }
  }, [sate]);

  const history = useHistory();

  useEffect(() => {
    if (elementRef.current[genreIndex]) {
      elementRef.current[genreIndex].scrollIntoView({
        block: "start",
      });
    }
  }, [elementRef, genreIndex]);

  const handleListGenreAssets = ({ value, genreIndex }) => {
    localStorage.setItem("onmovies", false);

    sessionStorage.setItem("scrollIndex", genreIndex - 1);

    elementRef.current[genreIndex] = React.createRef();

    if (selectedCategory == 1) {
      dispatch(
        LIVE_ACTION.getAllLiveAssetsForListing(
          ALL_GENRE_DATA[`"${genreId}"`]?.genreassetassociation
        )
      );

      if (USER_DETAILS != undefined && USER_DETAILS != null) {
        let props = {
          genreId: genreId,

          value: value,

          categoryId: 1,
        };

        dispatch(OFFSET_ACTION.getGenreDetailsProps(props));

        history.push({
          pathname: "/genreContainer",

          state: {
            genreId: genreId,

            value: value,

            categoryId: 1,
          },
        });
      } else {
        let props = {
          genreId: genreId,

          value: value,

          categoryId: 1,
        };

        dispatch(OFFSET_ACTION.getGenreDetailsProps(props));

        history.push({
          pathname: "/genreContainer",

          state: {
            genreId: genreId,

            value: value,

            categoryId: 1,
          },
        });
      }
    } else if (selectedCategory == 2) {
      let data = {
        value,

        genreDetailsWithAssetAssociation,
      };

      dispatch(OFFSET_MOVIE_ACTION.getMovieGenreAssetsForListing(data));

      history.push("/MovieGenreContainer");

      if (AUTHENTICATION == true) {
        let props = {
          genreId: genreId,

          value: value,

          categoryId: 2,
        };

        dispatch(OFFSET_ACTION.getGenreDetailsProps(props));

        history.push({
          pathname: "/MovieGenreContainer",

          state: {
            genreId: genreId,

            value: value,

            categoryId: 2,
          },
        });
      } else {
        let props = {
          genreId: genreId,

          value: value,

          categoryId: 2,
        };

        dispatch(OFFSET_ACTION.getGenreDetailsProps(props));

        history.push({
          pathname: "/MovieGenreContainer",

          state: {
            genreId: genreId,

            value: value,

            categoryId: 2,
          },
        });
      }
    } else if (selectedCategory == 3) {
      let data = {
        value,

        genreDetailsWithAssetAssociation,
      };

      dispatch(OFFSET_SERIES_ACTION.getSeriesGenreAssetsForListing(data));

      history.push("/seriesGenreContainer");

      if (USER_DETAILS != undefined && USER_DETAILS != null) {
        let props = {
          genreId: genreId,

          value: value,

          categoryId: 1,
        };

        dispatch(OFFSET_ACTION.getGenreDetailsProps(props));

        history.push({
          pathname: "/seriesGenreContainer",

          state: {
            genreId: genreId,

            value: value,

            categoryId: 3,
          },
        });
      } else {
        let props = {
          genreId: genreId,

          value: value,

          categoryId: 3,
        };

        dispatch(OFFSET_ACTION.getGenreDetailsProps(props));

        history.push({
          pathname: "/seriesGenreContainer",

          state: {
            genreId: genreId,

            value: value,

            categoryId: 3,
          },
        });
      }
    }
  };

  const isDisabled =
    genreCategory === 1 || genreCategory === 2 || genreCategory === 3;

  const resizeCountRef = useRef(0);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      console.log(
        genreDetailsWithAssetAssociation?.length +
          "@@@@@@@@@@@@@@@@@@@    " +
          // filteredAssets?.genreassetassociation?.length <=
          filteredAssets?.genreassetassociation?.length +
          "iioijio" +
          ASSETS_LENGTH[genreId]?.assetCount
      );
      if (
        selectedCategory === 1 &&
        filteredAssets?.genreassetassociation?.length <
          ASSETS_LENGTH[genreId]?.assetCount
      ) {
        resizeCountRef.current += 1;
        if ([1, 3, 4, 5, 8, 9].includes(resizeCountRef.current)) {
          console.log("@@@@@@@@@@@@@   newCount " + resizeCountRef.current);
          handleOffsetData();
        }
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // useEffect(() => {
  //   const handleResize = () => {
  //     const newWidth = window.innerWidth;
  //     console.log(
  //       genreDetailsWithAssetAssociation?.length +
  //         "@@@@@@@@@@@@@@@@@@@    " +
  //         // filteredAssets?.genreassetassociation?.length <=
  //         ASSETS_LENGTH[genreId]?.assetCount
  //     );
  //     console.log(
  //       "@@@@@@@@@@@@@@@@   " + JSON.stringify(genreDetailsWithAssetAssociation)
  //     );
  //     if (
  //       selectedCategory === 1 &&
  //       genreDetailsWithAssetAssociation?.length <
  //         // filteredAssets?.genreassetassociation?.length <=
  //         ASSETS_LENGTH[genreId]?.assetCount
  //     ) {
  //       setResizeCount((prev) => {
  //         const newCount = prev + 1;

  //         if (
  //           newCount === 1 ||
  //           newCount === 3 ||
  //           newCount === 4 ||
  //           newCount === 5 ||
  //           newCount === 8 ||
  //           newCount === 9
  //         ) {
  //           console.log("@@@@@@@@@@@@@   newCount" + newCount);

  //           handleOffsetData(); // Call on the 1st and 3rd resize
  //         }

  //         return newCount;
  //       });
  //     }

  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  const handleOffsetData = async () => {
    let localStorageGenreId = localStorage.getItem("genreId");

    let localStorageGenreArray = localStorage.getItem("genreArray");

    if (localStorageGenreArray?.length < 1) {
      let data = {
        genreId: genreId,

        offset: 5,
      };

      let dataArray = [];

      dataArray?.push(data);

      localStorage.setItem("genreArray", JSON.stringify(dataArray));
    }

    let localStorageGenreArray1 = localStorage.getItem("genreArray");

    let newArray = JSON.parse(localStorageGenreArray1);

    let checkIsGenreIdExist = newArray.filter((x) => x.genreId == genreId);

    if (checkIsGenreIdExist?.length == 0) {
      let data = {
        genreId: genreId,

        offset: 5,
      };

      let dataArray = newArray;

      dataArray?.push(data);

      localStorage.setItem("genreArray", JSON.stringify(dataArray));
    }

    localStorageGenreArray1 = localStorage.getItem("genreArray");

    newArray = JSON.parse(localStorageGenreArray1);

    let selectedIndex = null;

    let selectedData = null;

    newArray?.map((a, index) => {
      if (a?.genreId == genreId) {
        selectedIndex = index;

        selectedData = {
          genreId: genreId,

          offset: Number(a?.offset) + 5,
        };
      }
    });

    newArray.splice(selectedIndex, 1);

    newArray.splice(selectedIndex, 0, selectedData);

    localStorage.setItem("genreArray", JSON.stringify(newArray));

    if (localStorageGenreId != genreId) {
      localStorage.setItem("genreId", genreId);

      localStorage.setItem("offsetLength", 10);
    }

    let getFinalLocalStorageData = localStorage.getItem("genreArray");

    getFinalLocalStorageData = JSON.parse(getFinalLocalStorageData);

    let matchedData = getFinalLocalStorageData?.filter(
      (x) => x.genreId == genreId
    );

    let dataOffsetLenthFromLocalstorage = localStorage.getItem("offsetLength");

    console.log("@@@@@@@@@@@@@@@@@@@    " + matchedData?.[0]?.offset);
    let data = {
      offset: matchedData?.[0]?.offset,

      genreId: genreId,

      // limit: 5,
    };

    localStorage.setItem(
      "offsetLength",

      Number(dataOffsetLenthFromLocalstorage) + 5
    );

    dispatch(ALL_ASSET_ACTION.getLiveAssetsOffsetWise(data));

    // dispatch(OFFSET_ACTION.getOffsetLength(OFFSET + 5));

    // }
  };

  const showNavigation = genreDetailsWithAssetAssociation?.length > 4;

  return (
    <div className="col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 pr-4 pl-4">
      <div className="iq-main-header  align-items-center justify-content-between p-0 m-0 col-sm-12 row">
        {genreAssetAssociation?.length > 0 ? (
          <div
            className=" col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 row"
            ref={(ref) =>
              (elementRef.current[sessionStorage.getItem("scrollIndex")] = ref)
            }
          >
            <h5
              className="main-title p-0 mt-2 col-11  col-sm-11 col-xs-11 col-md-11 col-lg-11"
              style={{
                cursor: "pointer",

                fontFamily: "Montserrat,sans-serif",

                color: "white",
              }}
              onClick={() =>
                !isDisabled && handleListGenreAssets({ value, genreIndex })
              }
            >
              {value}
            </h5>

            {genreAssetAssociation?.length > 0 && (
              <div
                className="col-1 col-sm-1 col-xs-1 col-md-1 col-lg-1"
                style={{
                  color: "white",

                  cursor: "pointer",

                  textAlign: "right",

                  float: "right",

                  justifyContent: "right",
                }}
              ></div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      <Swiper
        slidesPerView="auto" // Allow Swiper to automatically determine slides per view
        centeredSlides={false}
        slidesPerGroupSkip={1}
        grabCursor={true}
        keyboard={{
          enabled: true,
        }}
        scrollbar={false}
        navigation={showNavigation} // Show navigation only if there are more than 4 slides
        pagination={{
          clickable: false,
        }}
        modules={[Keyboard, Navigation]}
        className={`mySwiper ${selectedCategory === 1 ? "category-1" : ""}`}
        spaceBetween={5}
        onSlideChange={handleSlideChange}
      >
        {" "}
        {genreDetailsWithAssetAssociation?.map((item, index) => (
          <SwiperSlide key={item?.id}>
            {/* {console.log(genreCategory == 2 && "iddddd" + item?.id)} */}

            <div>
              {item?.active == 4 && genreAssetAssociation?.length > 0 ? (
                <AdsComponent
                  key={item?.id}
                  assetUrl={item?.videoAddUrl}
                  genreId={genreId}
                  addId={item?.id}
                  userId={USER_DETAILS?.id}
                  platformId={CURRENT_PLATFORM?.orgId}
                  DEVICE_TOKEN={DEVICE_TOKEN}
                  CONFIGURATION_DAS={CONFIGURATION_DAS}
                  AUTHENTICATION={AUTHENTICATION}
                  selectedCategory={selectedCategory}
                />
              ) : selectedCategory == 1 ? (
                <DisplayMovieImage
                  finalGenreAssets={genreDetailsWithAssetAssociation}
                  key={index}
                  item={item}
                  genreIndex={genreIndex}
                  index={index}
                  from={"liveCatogory"}
                  value={value}
                />
              ) : selectedCategory == 2 ? (
                <MovieScreenDisplayMovieImage
                  item={item}
                  cindex={genreIndex}
                  index={index}
                  from={"movieCatogory"}
                />
              ) : (
                selectedCategory == 3 && (
                  <SeriesPageDisplayImage
                    item={item}
                    cindex={genreIndex}
                    index={index}
                    from={"seriesCatogory"}
                  />
                )
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MovieCatogory;
