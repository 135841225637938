/** @format */

import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import "./displayMovieImage.css";
import "./MoviesScreens/movieScreenModal.css";
import * as VIDEO_ACTION from "./../Redux/VideoRedux/action";
import ProgressBarr from "./progress-bar/ProgressBar";
import { Skeleton } from "@mui/material";
import {
  allLiveAssetSelector,
  getGeneralAdsDetailSelector,
} from "../Redux/AllLiveDetails/selectors";
import {
  getAlacarteDetailsSelector,
  getPackageDetailsSelector,
  getPayPerViewDetailsSelector,
} from "../Redux/package&subscriptions/selector";
import { getLatestEpgDetailsSelector } from "../Redux/LatestEPG/selector";
import SubscribeTitle from "./SubscribeTitle";
import { newImageIpPath } from "../App";
import { getCurrentPlatformSelector } from "../Redux/platforms/selectors";
const DisplayMovieImage = ({
  item,
  LIVEASSETS,
  GENERAL_ADS_DETAILS,
  PACKAGE_DETAILS,
  ALACARTE_DETAILS,
  PAY_PER_VIEW_DETAILS,
  LATEST_EPG_DETAILS,
  cindex,
  from,
  value,
  CURRENT_PLATFORM,
}) => {
  const imageRef = useRef({});
  const [imageScr, setImageSrc] = useState();
  let assetDetails = LIVEASSETS?.liveAssetDetails;
  let flag = 0;
  let thumnailUrl = null;
  let id = null;
  let epg_title = null;
  let startTimeStamp = null;
  let stopTimeStamp = null;
  let epgDta = null;
  let thumbnailPath = null;
  let assetId = null;
  let isPPVRequired = null;

  const element = assetDetails?.[item?.assetId];

  // console.log(
  //   JSON.stringify(element) +
  //     "       opopopo" +
  //     value +
  //     "      pooja" +
  //     JSON.stringify(item)
  // );

  isPPVRequired = element?.isPPVRequired;
  thumbnailPath = element?.liveThumbnailPath;
  if (
    element?.thumbnails != undefined &&
    element?.thumbnails != null &&
    element?.thumbnails?.length > 0
  ) {
    thumnailUrl = newImageIpPath + element?.thumbnails[0]?.thumbnailUrl;
  }
  id = element?.id;
  assetId = element?.id;

  epg_title = LATEST_EPG_DETAILS?.[assetId]?.currentEpg?.title;
  startTimeStamp =
    LATEST_EPG_DETAILS?.[assetId]?.currentEpg?.startTimeTimestamp;
  stopTimeStamp = LATEST_EPG_DETAILS?.[assetId]?.currentEpg?.stopTimeTimestamp;
  epgDta = LATEST_EPG_DETAILS?.[assetId]?.currentEpg;

  let date = new Date();

  let timestamp = date?.getTime();
  //  ***************PACKAGE_DETAILS**************
  for (var p = 0; p < element?.packages?.length; p++) {
    let stopDate =
      PACKAGE_DETAILS?.[element?.packages?.[p]?.assetGroupId]?.stopDate;

    let stopDateTimeStamp = parseInt(Date.parse(stopDate));
    if (
      element?.packages[p]?.assetGroupId ==
        PACKAGE_DETAILS?.[element?.packages[p]?.assetGroupId]?.packageId &&
      timestamp < stopDateTimeStamp
    ) {
      flag = 1;
    }
  }

  // ************ALACARTE_DETAILS***********************
  let stopDateAlacarte = ALACARTE_DETAILS?.[assetId]?.expiryDate;
  let stopDateTimeStampAlacarte = parseInt(Date.parse(stopDateAlacarte));

  if (
    assetId == ALACARTE_DETAILS?.[assetId]?.assetId &&
    ALACARTE_DETAILS?.[assetId]?.categoryId == 1 &&
    timestamp < stopDateTimeStampAlacarte
  ) {
    flag = 1;
  }
  // *****************PAY_PER_VIEW_DETAILS*************
  let stopDatePPV = PAY_PER_VIEW_DETAILS?.[1]?.[assetId]?.expiryDate;
  let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

  if (
    assetId == PAY_PER_VIEW_DETAILS?.[1]?.[assetId]?.assetId &&
    // PAY_PER_VIEW_DETAILS?.[assetId]?.categoryId == 1 &&
    timestamp < stopDateTimeStampPPV
  ) {
    flag = 1;
  }

  const history = useHistory();
  const dispatch = useDispatch();

  const handleDisplayMovie = (flag, id) => {
    history.push({
      pathname: "/liveDetailsPage",
      state: {
        flagss: flag,

        from: from,
      },
    });

    localStorage.setItem("onmovies", false);

    sessionStorage.setItem("scrollIndex", cindex - 1);
    let requestData = {
      id: item?.assetId,
      account_id: CURRENT_PLATFORM?.orgId,
    };
    if (element != undefined && element != null) {
      dispatch(VIDEO_ACTION.getLiveChannelData(element));
    } else {
      dispatch(VIDEO_ACTION.getLiveDetailsStart(requestData));
    }
  };

  let totalDuration;
  let timeEllapsed;
  let percentage;

  totalDuration = stopTimeStamp - startTimeStamp;

  let currentDate = new Date();
  let milisecond = currentDate.getTime();

  timeEllapsed = milisecond - startTimeStamp;
  percentage = (timeEllapsed / totalDuration) * 100;

  let ampm = null;

  let pmam = null;
  let newStartTimeStampDate = new Date(Number(startTimeStamp));
  let newStopTimeStampDate = new Date(Number(stopTimeStamp));

  let newStartTimeStampHour = newStartTimeStampDate.getHours();
  if (parseInt(newStartTimeStampHour) <= 9) {
    newStartTimeStampHour = "0" + newStartTimeStampHour;
  }
  let newStartTimeStampMinute = newStartTimeStampDate.getMinutes();
  if (parseInt(newStartTimeStampMinute) <= 9) {
    newStartTimeStampMinute = "0" + newStartTimeStampMinute;
  }

  let newStopTimeStampHour = newStopTimeStampDate.getHours();
  if (parseInt(newStopTimeStampHour) <= 9) {
    newStopTimeStampHour = "0" + newStopTimeStampHour;
  }
  let newStopTimeStampMinutes = newStopTimeStampDate.getMinutes();
  if (parseInt(newStopTimeStampMinutes) <= 9) {
    newStopTimeStampMinutes = "0" + newStopTimeStampMinutes;
  }

  if (epgDta != undefined) {
    if (newStartTimeStampDate.getHours() >= 12) {
      ampm = "PM";
    } else {
      ampm = "AM";
    }

    if (newStopTimeStampDate.getHours() >= 12) {
      pmam = "PM";
    } else {
      pmam = "AM";
    }
  }

  const checkImage = (thumbnailPath) => {
    let testThumbnail = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(
      thumbnailPath
    );
    return testThumbnail;
  };

  useEffect(() => {
    let testThumbnail = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(
      assetDetails?.[item?.assetId]?.liveThumbnailPath
    );
    var randomId = Math.floor(Math.random() * (10 - 1 + 1)) + 1;
    if (testThumbnail == false) {
      setImageSrc(
        assetDetails?.[item?.assetId]?.liveThumbnailPath +
          "/thumbnail.png?id=" +
          randomId
      );
    } else {
      setImageSrc(
        newImageIpPath + assetDetails?.[item?.assetId]?.liveThumbnailPath
      );
    }
  }, [assetDetails?.[item?.assetId]?.liveThumbnailPath]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      let testThumbnail = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(
        assetDetails?.[item?.assetId]?.liveThumbnailPath
      );
      var randomId = Math.floor(Math.random() * (10 - 1 + 1)) + 1;
      if (testThumbnail == false) {
        setImageSrc(
          assetDetails?.[item?.assetId]?.liveThumbnailPath +
            "/thumbnail.png?id=" +
            randomId
        );
      } else {
        setImageSrc(
          newImageIpPath + assetDetails?.[item?.assetId]?.liveThumbnailPath
        );
      }
    }, 20000);

    return () => clearInterval(intervalId);
  }, []);
  const handleImage = () => {
    imageRef.current.src = "images/login/black.png";
  };
  const [isError, setIsError] = useState(false);

  const handleChannelLogoError = () => {
    setIsError(true);
  };
  return (
    <div>
      {Number(item?.active) == 1 && element != undefined && (
        // <h1>{value + " " + element.title}</h1>

        <div className="favorites-contens p-0" style={{ cursor: "pointer" }}>
          <ul className=" list-inline   p-0 mb-0 iq-rtl-direction">
            <li
              className="slide-item mt-3"
              onClick={() => handleDisplayMovie(flag, item?.assetId)}
            >
              <div className="block-images position-relative">
                <div
                  className="img-box"
                  style={{
                    position: "relative",

                    boxShadow: "20px 20px 50px rgba(0, 0, 0, 0.5)",
                    borderRadius: "10px",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    backdropFilter: "blur(5px)",
                  }}
                >
                  {" "}
                  {assetDetails?.[item?.assetId]?.liveThumbnailPath !=
                    undefined &&
                  assetDetails?.[item?.assetId]?.liveThumbnailPath != null ? (
                    imageScr == undefined ? (
                      <img
                        ref={imageRef}
                        onError={handleImage}
                        // onError="this.src='images/login/black.png'"
                        style={{
                          width: "300px",
                          height: "180px",
                          objectFit: "cover",
                        }}
                        src={
                          checkImage(
                            assetDetails?.[item?.assetId]?.liveThumbnailPath
                          ) != true
                            ? assetDetails?.[item?.assetId]?.liveThumbnailPath +
                              "/thumbnail.png"
                            : newImageIpPath +
                              assetDetails?.[item?.assetId]?.liveThumbnailPath
                        }
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        ref={imageRef}
                        onError={handleImage}
                        // onError="this.src='images/login/black.png'"
                        style={{
                          width: "300px",
                          height: "180px",
                          objectFit: "cover",
                        }}
                        src={imageScr}
                        className="img-fluid"
                      />
                    )
                  ) : (
                    <div>
                      <Skeleton
                        variant="circular"
                        width={40}
                        height={40}
                        sx={{ bgcolor: "grey.800" }}
                      />

                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

                      <Skeleton
                        variant="rectangular"
                        width={260}
                        height={50}
                        sx={{ bgcolor: "grey.800" }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

                      <Skeleton
                        variant="rounded"
                        width={260}
                        height={40}
                        sx={{ bgcolor: "grey.800" }}
                      />
                    </div>
                  )}
                </div>{" "}
                {item?.isPurchased == true && (
                  <svg
                    style={{
                      alignSelf: "flex-end",
                      position: "absolute",
                      top: "0",
                      right: "0",
                      padding: "5px",
                      color: "#FFD700",
                      // border: "1px solid #000",
                      // borderRadius: "1px",
                    }}
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50px"
                    height="50px"
                    viewBox="0 0 1280.000000 815.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,815.000000) scale(0.100000,-0.100000)"
                      fill="#FFD700"
                      stroke="none"
                    >
                      <path
                        d="M6224 8131 c-137 -37 -202 -83 -331 -229 -139 -159 -190 -310 -179
        -527 9 -184 62 -316 185 -461 38 -44 91 -97 118 -117 55 -40 169 -97 195 -97
        9 0 19 -4 22 -9 10 -16 -743 -2610 -779 -2686 -48 -100 -88 -150 -141 -176
        -41 -19 -50 -20 -86 -10 -55 17 -124 88 -185 191 -27 47 -343 465 -702 929
        l-652 845 46 39 c209 179 315 387 315 617 0 172 -47 303 -159 442 -132 164
        -238 240 -389 279 -133 34 -263 18 -402 -49 -58 -28 -93 -55 -159 -122 -136
        -139 -209 -256 -242 -390 -17 -71 -17 -249 0 -320 19 -77 81 -207 132 -276
        116 -158 250 -254 404 -291 39 -9 71 -17 72 -18 3 -2 -194 -1964 -203 -2020
        -12 -74 -54 -192 -84 -233 -75 -104 -178 -97 -335 23 -38 29 -385 259 -770
        510 -385 251 -706 463 -713 470 -11 10 -8 21 22 63 142 197 175 498 79 726
        -83 199 -274 374 -468 432 -73 21 -217 24 -295 5 -30 -7 -93 -31 -140 -53 -71
        -35 -100 -56 -180 -137 -74 -74 -105 -115 -137 -176 -68 -131 -78 -178 -78
        -355 0 -135 3 -165 24 -230 98 -314 354 -513 661 -513 109 -1 171 15 268 68
        35 20 65 35 67 33 5 -7 275 -516 383 -723 327 -629 481 -1071 562 -1610 6 -38
        13 -82 16 -98 l6 -27 4398 0 4397 0 7 52 c12 95 76 400 112 535 77 294 201
        611 374 962 103 209 458 890 471 905 4 5 21 -1 37 -13 80 -56 244 -98 346 -87
        174 20 302 81 426 206 47 47 100 111 119 142 197 336 129 725 -172 978 -77 65
        -183 121 -267 141 -71 17 -200 17 -270 0 -127 -31 -278 -131 -375 -249 -124
        -150 -172 -298 -162 -504 7 -163 52 -301 134 -416 25 -36 30 -49 20 -58 -6 -6
        -330 -218 -718 -471 -388 -254 -737 -485 -775 -514 -89 -67 -137 -89 -200 -89
        -94 0 -157 69 -194 214 -14 57 -48 371 -115 1089 -52 555 -95 1013 -95 1018 0
        5 7 9 14 9 38 0 179 54 233 89 118 76 246 231 299 363 69 168 72 395 7 558
        -39 98 -87 165 -193 271 -107 107 -188 155 -315 185 -135 31 -299 2 -432 -78
        -70 -42 -202 -174 -258 -258 -147 -223 -146 -563 4 -792 49 -76 137 -171 206
        -225 l40 -30 -31 -39 c-288 -365 -1292 -1681 -1329 -1743 -56 -93 -138 -175
        -185 -184 -77 -16 -158 60 -216 203 -12 30 -76 240 -142 465 -66 226 -238 810
        -382 1300 -143 489 -258 895 -256 902 3 7 12 13 20 13 7 0 51 18 96 41 100 50
        237 180 294 279 116 199 139 467 59 670 -74 188 -263 377 -432 431 -96 31
        -271 36 -367 10z"
                      />
                      <path d="M1990 660 l0 -660 4395 0 4395 0 2 660 3 660 -4397 0 -4398 0 0 -660z" />
                    </g>
                  </svg>
                )}
                <div className="block-description">
                  <div
                    className="movie-time d-flex align-items-center my-2"
                    style={{ marginBottom: "60px", height: "64px" }}
                  >
                    {" "}
                    {isError ? (
                      <div
                        style={{
                          maxHeight: "64px",
                          maxWidth: "64px",
                          minHeight: "24px",
                          minWidth: "24px",
                          backgroundColor: "transparent", // Transparent div
                          border: "1px solid #ccc", // Optional border to make it visible
                        }}
                      />
                    ) : (
                      <img
                        src={thumnailUrl}
                        style={{
                          maxHeight: "64px",
                          maxWidth: "64px",
                          minHeight: "24px",
                          minWidth: "24px",
                        }}
                        onError={handleChannelLogoError}
                      />
                    )}
                  </div>
                  {isPPVRequired == 1 &&
                    item?.isPurchased != true &&
                    PAY_PER_VIEW_DETAILS?.[1]?.[element?.id]?.categoryId !=
                      1 && (
                      <div style={{ width: "276px" }}>
                        <SubscribeTitle />
                      </div>
                    )}
                </div>
                <div
                  style={{
                    zIndex: 200,
                    position: "absolute",
                    left: "3%",
                    right: "3%",
                    bottom: 2,
                    width: "94%",
                    height: "5%",
                  }}
                >
                  {epgDta != undefined && epgDta != null ? (
                    <ProgressBarr completed={percentage} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </li>
          </ul>
          <div className="col-lg-12 mt-2" style={{ height: "50px" }}>
            <div style={{ alignItem: "center" }}>
              <div
                className=" p-0"
                style={{
                  color: "white",
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "16px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "270px",
                  display: "block",
                  overflow: "hidden",
                }}
              >
                {epg_title}
              </div>
              {epgDta != undefined &&
              epgDta != null &&
              newStartTimeStampDate != undefined &&
              newStartTimeStampDate != null &&
              newStopTimeStampDate != undefined &&
              newStopTimeStampDate != null ? (
                <span
                  className="p-0"
                  style={{
                    color: "gray",
                    fontFamily: "Montserrat,sans-serif",
                  }}
                >
                  <span
                    className="p-0"
                    style={{
                      color: "red",

                      fontFamily: "Montserrat,sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    On Now :
                  </span>
                  &nbsp;
                  {newStartTimeStampHour + ":" + newStartTimeStampMinute} -{" "}
                  {newStopTimeStampHour + ":" + newStopTimeStampMinutes}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  GENERAL_ADS_DETAILS: getGeneralAdsDetailSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  ALACARTE_DETAILS: getAlacarteDetailsSelector,
  PAY_PER_VIEW_DETAILS: getPayPerViewDetailsSelector,
  LATEST_EPG_DETAILS: getLatestEpgDetailsSelector,
  LIVEASSETS: allLiveAssetSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
});

export default connect(mapStateToProps)(DisplayMovieImage);
