import videojs from "video.js";
import "./playlistLivePlugin.css";
import "videojs-contrib-eme";

const playlistLivePlugin = function(playlistItems, video_index = 0) {
  const player = this;
  let playlistButton,
    playlistContainer,
    playerTitle,
    skipButton,
    closeButton,
    postercloseButton,
    posteradimg,
    posteradContainer,
    playlistBackButton,
    playerIcons,
    skipThumbnail,
    searchInput,
    skipContainer,
    skipThumbnailImg,
    searchBox;

  let selectedIndex = null;
  let adPlaying = false;
  let adConfig = []; // To store ad configuration
  let skipconfig = [];
  let mainVideoTime;
  let playedAds = new Set();
  let posterads = false;
  let posterclosebtn = false;
  let playListContainer = false;
  let searchEnabled = false;
  let currentAdId = null; // Variable to store the current ad ID

  function initialize() {
    const playerEl = player.el();
    if (!playerEl) {
      console.error("Player element not found.");
      return;
    }

    // Add player icons
    playerIcons = document.createElement("div");
    playerIcons.className = "vjs-icons";
    playerIcons.style.display = "block";
    player.el().appendChild(playerIcons);

    //create player back button
    playlistBackButton = document.createElement("div");
    playlistBackButton.className = "vjs-playerback-button";
    playlistBackButton.innerHTML = ` <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="white"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/></svg>`;
    playlistBackButton.style.display = "flex";
    playlistBackButton.addEventListener("click", goBack);
    playerIcons.appendChild(playlistBackButton);

    // Create playlist button
    playlistButton = document.createElement("div");
    playlistButton.className = "vjs-playlist-icon-button";
    playlistButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 -960 960 960" width="34px" fill="white"><path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"/></svg>`;
    playlistButton.style.display = "flex";
    playlistButton.title = "Playlist";
    playlistButton.addEventListener("click", togglePlaylist);
    playerIcons.appendChild(playlistButton);
    playerEl.appendChild(playerIcons);

    // Add player episode title
    playerTitle = document.createElement("div");
    playerTitle.className = "vjs-playerTitle";
    playerTitle.textContent = "";
    playerTitle.style.display = "none";
    player.el().appendChild(playerTitle);

    // Create playlist container
    playlistContainer = document.createElement("div");
    playlistContainer.style.left = "-300px";
    playlistContainer.style.display = "none";
    playlistContainer.className = "vjs-playlist-container";

    // Create container header
    const containerHeader = document.createElement("div");
    containerHeader.className = "vjs-playlist-header";

    // Add playlist title
    const playlistTitle = document.createElement("div");
    playlistTitle.className = "vjs-title";
    playlistTitle.textContent = "Channel List";

    // Add episode count display
    const episodeCount = document.createElement("div");
    episodeCount.className = "vjs-episode-count";
    episodeCount.textContent = `0/${playlistItems?.length}`;
    containerHeader.appendChild(playlistTitle);
    containerHeader.appendChild(episodeCount);

    // Add  search  icon display
    const searchIcon = document.createElement("div");
    searchIcon.className = "vjs-episode-search";
    searchIcon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="white" cursor="pointer" justify-content:"center"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Zm-60-80v-200l160 100-160 100Z"/></svg>  `;
    searchIcon.addEventListener("click", openSearchInputBox);
    searchIcon.title = "search";

    // Add search input box display
    searchBox = document.createElement("div");
    searchBox.className = "vjs-episode-search-box";
    searchBox.style.display = "none";

    searchInput = document.createElement("input");
    searchInput.type = "text";
    searchInput.placeholder = "Search...";
    searchInput.addEventListener("input", searchPlaylist);
    searchBox.appendChild(searchInput);
    // Create input box close button
    const inputCloseButton = document.createElement("button");
    inputCloseButton.className = "vjs-input-close-button";
    inputCloseButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="black"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>`;
    inputCloseButton.addEventListener("click", () => {
      searchEnabled = false;
      searchInput.value = "";
      searchInput.dispatchEvent(new Event("input"));
    });
    searchBox.appendChild(inputCloseButton);

    // Create close button
    closeButton = document.createElement("button");
    closeButton.className = "vjs-close-button";
    closeButton.innerHTML = `<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 19-7-7 7-7"/>
</svg>
`;
    closeButton.addEventListener("click", togglePlaylist);
    containerHeader.appendChild(searchIcon);
    containerHeader.appendChild(closeButton);
    playlistContainer.appendChild(containerHeader);
    containerHeader.appendChild(searchBox);

    playlistItems.forEach((item, index) => {
      const button = document.createElement("div");
      button.className = "vjs-playlist-item";
      button.title = item.title;

      // Create image element===============
      const playlistImgContainer = document.createElement("div");
      playlistImgContainer.className = "vjs-img-container";
      const img = document.createElement("img");
      img.src = item.poster;
      img.onerror = function() {
        const blackDiv = document.createElement("div");
        blackDiv.style.backgroundColor = "#000"; // Set the div's background to black
        blackDiv.style.width = "100%"; // Make sure the black div covers the entire image area
        blackDiv.style.height = "100%";
        playlistImgContainer.innerHTML = ""; // Clear any previous content in the container
        playlistImgContainer.appendChild(blackDiv); // Append the black div
      };
      // Create title overlay
      const titleOverlay = document.createElement("div");
      titleOverlay.className = "title-overlay";
      titleOverlay.textContent = item.title;

      // Append elements
      playlistImgContainer.appendChild(img);
      playlistImgContainer.appendChild(titleOverlay);

      const playlistEpisodeDetails = document.createElement("div");
      playlistEpisodeDetails.className = "vjs-playlist-episode-Details";

      //playlist episode ===
      const episode = document.createElement("div");
      episode.className = "vjs-playlist-episode";
      episode.textContent = ` ${item.logicalChannelNumber}${"  "}${item.title}`;
      episode.style.border = "";

      //search item LCN
      const episodeLcn = document.createElement("text");
      episodeLcn.className = "vjs-playlist-episodeLcn";
      episodeLcn.textContent = item.searchItem;
      episodeLcn.style.display = "none";
      playlistEpisodeDetails.appendChild(episodeLcn);

      //playlist episode ===
      const episodeDuration = document.createElement("div");
      episodeDuration.className = "vjs-playlist-episode-duration";
      episodeDuration.textContent = item.duration;
      episodeDuration.style.border = "";

      // Append image and text to button
      button.appendChild(playlistImgContainer);
      playlistEpisodeDetails.appendChild(episode);
      playlistEpisodeDetails.appendChild(episodeDuration);
      button.appendChild(playlistEpisodeDetails);
      button.addEventListener("click", () => {
        togglePlaylist();

        playVideo(index);

        // playVideo(index, item.cw_duration ? parseFloat(item.cw_duration) : 0);
      });

      playlistContainer.appendChild(button);
      // if (item.adConfig && Array.isArray(item.adConfig)) {
      //   adConfig.push(item.adConfig.map((ad) => ({ index, ad })));
      // }
    });

    // Add a gap element if it doesn't already exist
    if (!document.querySelector(".playlist-gap")) {
      const gapElement = document.createElement("div");
      gapElement.className = "playlist-gap";
      playlistContainer.appendChild(gapElement);
    }
    player.el().appendChild(playlistContainer);

    // Create skip button for ads
    skipContainer = document.createElement("div");
    skipContainer.className = "skip-ad-container";
    skipContainer.style.display = "none";

    skipButton = document.createElement("div");
    skipButton.className = "skip-ad-button";

    skipThumbnail = document.createElement("div");
    skipThumbnail.className = "skip-ad-thumbnail";
    skipThumbnailImg = document.createElement("img");
    skipThumbnailImg.className = "skip-ad-thumbnail-img";
    skipContainer.appendChild(skipButton);
    skipThumbnail.appendChild(skipThumbnailImg);
    skipContainer.appendChild(skipThumbnail);
    player.el().appendChild(skipContainer);

    // poster container baby
    posteradContainer = document.createElement("div");
    posteradContainer.className = "vjs-posterAd-container";
    posteradContainer.style.display = "none";

    posteradimg = document.createElement("img");
    posteradimg.className = "vjs-posterAd";
    // posteradimg.src = src;

    postercloseButton = document.createElement("button");
    postercloseButton.className = "vjs-posterads-close-button";
    postercloseButton.innerHTML = "&times;";
    postercloseButton.style.display = "none";

    posteradContainer.appendChild(posteradimg);
    posteradContainer.appendChild(postercloseButton);
    player.el().appendChild(posteradContainer);

    player.on("useractive", () => {
      if (!adPlaying) {
        playlistButton.style.display = "flex";
        playlistContainer.style.display = "blcok";
        playerTitle.style.display = "flex";
        playerIcons.style.display = "block";
      }
    });

    // player.on("userinactive", () => {
    //   playerTitle.style.display = "none";
    //   playerIcons.style.display = "none";
    // });
    player.on("userinactive", handleUserInactive);

    // window.addEventListener("resize", adjustPlaylistPosition);

    player.on("ended", playNextVideo);
  }
  const handleUserInactive = () => {
    playerTitle.style.display = "none";
    playerIcons.style.display = "none";
  };

  initialize();
  playVideo(video_index);
  player.on("error", errordisplay);

  function openSearchInputBox() {
    if (searchBox.style.display == "none") {
      searchBox.style.display = "flex";
    } else {
      searchBox.style.display = "none";
    }
  }

  function togglePlaylist() {
    if (
      playlistContainer.style.display === " " ||
      playlistContainer.style.display === "none"
    ) {
      playListContainer = true;
      searchEnabled = true;

      playlistContainer.style.display = "block";
      let selectedEpisode = [];
      const playlistItems = document.querySelectorAll(".vjs-playlist-item")[
        selectedIndex
      ];

      // selectedEpisode = Array.from(playlistItems).filter((item) =>
      //   item.querySelector(".vjs-playlist-episode").textContent.toLowerCase()
      // );
      // selectedEpisode[selectedIndex].scrollIntoView({
      playlistItems.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setTimeout(() => {
        playlistContainer.style.left = "5px"; // Slide in
      }, 10);
    } else {
      playListContainer = false;
      playlistContainer.style.left = "-400px"; // Slide out
      setTimeout(() => {
        playlistContainer.style.display = "none";
      }, 400); // Match the transition duration
    }
  }

  // Close the playlist container when clicking outside of it
  document.addEventListener("click", function(event) {
    if (
      playlistContainer.style.display === "block" &&
      !playlistContainer.contains(event.target) &&
      !playlistButton.contains(event.target)
    ) {
      togglePlaylist();
    }
  });

  function convertDurationToSeconds(duration) {
    const parts = duration.split(":");
    const seconds = parseInt(parts[parts.length - 1], 10) || 0;
    const minutes = parseInt(parts[parts.length - 2], 10) || 0;
    const hours = parts.length === 3 ? parseInt(parts[0], 10) || 0 : 0;
    return hours * 3600 + minutes * 60 + seconds;
  }

  function adjustPlaylistPosition() {
    const playerRect = player.el().getBoundingClientRect();
    const controlBarHeight = player.controlBar.el().offsetHeight;
    playlistContainer.style.maxHeight = `${playerRect.height -
      controlBarHeight -
      15}px`;
  }

  // Add event listeners for keyboard controls
  document.addEventListener("keydown", function(event) {
    // Trigger userActive
    player.userActive(true);
    if (event.code === "ArrowRight") {
      // Seek forward 10 seconds
      seekVideo(10);
    } else if (event.code === "ArrowLeft") {
      // Seek backward 10 seconds
      seekVideo(-10);
    } else if (event.code === "ArrowUp" && playListContainer == false) {
      // Increase volume
      changeVolume(0.1);
    } else if (event.code === "ArrowDown" && playListContainer == false) {
      // Decrease volume
      changeVolume(-0.1);
    }
  });
  function changeVolume(amount) {
    if (player.volume() != null) {
      player.volume(player.volume() + amount);
    }
  }

  function seekVideo(seconds) {
    if (!adPlaying && player.currentTime() != null) {
      let newTime = player.currentTime() + seconds;
      if (newTime < 0) newTime = 0; // Ensure time doesn't go below 0
      if (newTime > player.duration()) newTime = player.duration(); // Ensure time doesn't exceed duration
      player.ready(() => {
        player?.currentTime(newTime);
      });
      // player.currentTime(newTime);
    }
  }

  function playVideo(index, startTime) {
    if (index === selectedIndex) {
      return; // Return early if the same index is selected
    } else {
      if (posterads) {
        posteradContainer.style.display = "none";
      }
      posterclosebtn = false;
      posterads = false;
      if (
        playlistItems[index] &&
        playlistItems[index].sources &&
        playlistItems[index].sources.length > 0
      ) {
        var selectedItem = playlistItems[index];
        const videoSrc = selectedItem.sources[0].src;
        const keySystems = selectedItem.sources[0].keySystems;

        if (keySystems) {
          player.ready(() => {
            player.src({
              src: videoSrc,
              type: getVideoType(videoSrc),
              keySystems: keySystems,
            });
            var bigPlayButton = player.getChild("bigPlayButton");
            if (bigPlayButton) {
              bigPlayButton.hide();
            }
          });
          player.one("loadedmetadata", () => {
            var bigPlayButton = player.getChild("bigPlayButton");
            if (bigPlayButton) {
              bigPlayButton.hide();
            }
            player.currentTime(startTime);
            player.play();
          });
        } else {
          player.one("loadedmetadata", () => {
            var bigPlayButton = player.getChild("bigPlayButton");
            if (bigPlayButton) {
              bigPlayButton.hide();
            }
            player.currentTime(startTime);
            player.play();
          });
        }

        // Filter and assign the adConfig array to include only ads with time greater than or equal to cw_duration
        adConfig = (playlistItems[index].adConfig || []).filter(
          (ad) => Object.keys(ad).length !== 0
        ); // Remove empty objects
        selectedIndex = index;
        highlightSelectedItem(index);
        updateCurrentPlaying(index);

        player.on("timeupdate", checkAdTime);
        player.on("timeupdate", checkPosterAd);
      } else {
        console.error(`No valid source found for item at index ${index}`);
      }
    }

    // Dispatch the playlist_change event
    const playlist_changeEvent = new CustomEvent("playlist_change", {
      detail: {
        index: selectedIndex,
        episode: selectedItem,
      },
    });
    player.el().dispatchEvent(playlist_changeEvent);
  }

  function getVideoType(videoSrc) {
    let videoType = "";

    if (videoSrc) {
      if (videoSrc.endsWith(".mp4")) {
        videoType = "video/mp4";
      } else if (videoSrc.endsWith(".m3u8")) {
        videoType = "application/x-mpegURL";
      } else if (videoSrc.endsWith(".mpd")) {
        videoType = "application/dash+xml";
      }
    }

    return videoType;
  }

  function updateCurrentPlaying(index) {
    const episodeCount = document.querySelector(".vjs-episode-count");
    if (episodeCount) {
      episodeCount.textContent = `${index + 1}/${playlistItems.length}`;
    }
  }
  let currentMatchIndex = -1;

  let matchingItems = [];
  // Function to search and highlight the playlist items
  function searchPlaylist(event) {
    searchEnabled = true;
    const searchTerm = event.target.value.toLowerCase();
    const playlistItems = document.querySelectorAll(".vjs-playlist-item");

    playlistItems.forEach((item) => {
      const episodeTitle = item
        .querySelector(".vjs-playlist-episodeLcn")
        .textContent.toLowerCase();
      if (searchTerm === "") {
        item.style.background = ""; // Remove highlight if search term is cleared
      } else if (episodeTitle.includes(searchTerm)) {
        item.style.background =
          "linear-gradient(to bottom,rgb(164 155 155), rgb(50 43 43) 100%)"; // Highlight the matching episode
      } else {
        item.style.background = ""; // Remove highlight
      }
    });

    matchingItems = Array.from(playlistItems).filter((item) =>
      item
        .querySelector(".vjs-playlist-episodeLcn")
        .textContent.toLowerCase()
        .includes(searchTerm)
    );

    if (searchTerm === "") {
      currentMatchIndex = -1;
      matchingItems = []; // Clear the matching items when search term is empty
    } else if (matchingItems.length > 0) {
      // Scroll to the first matching item immediately
      currentMatchIndex = 0;
      matchingItems[currentMatchIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      matchingItems[currentMatchIndex].style.background =
        "linear-gradient(328deg, rgb(118 122 9), rgb(228 243 115 / 78%))";
    }

    // Clear highlight and search input box on item click
    playlistItems.forEach((item) => {
      item.addEventListener("click", () => {
        playlistItems.forEach((item) => {
          item.style.background = ""; // Clear highlight
        });
        document.querySelector(".vjs-episode-search-box").value = ""; // Clear search input box
        currentMatchIndex = -1; // Reset the current match index
      });
    });
  }
  document.addEventListener("keydown", function(event) {
    // if (event.key === "Enter" && matchingItems.length > 0) {
    if (searchEnabled) {
      if (event.key === "ArrowDown" && matchingItems.length > 0) {
        // Remove highlight from previously focused item
        if (currentMatchIndex !== -1) {
          matchingItems[currentMatchIndex].style.background =
            "linear-gradient(to bottom,rgb(164 155 155), rgb(50 43 43) 100%)";
        }

        // Move to the next item
        currentMatchIndex = (currentMatchIndex + 1) % matchingItems.length;
        matchingItems[currentMatchIndex].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        // Apply focus style to the current item
        matchingItems[currentMatchIndex].style.background =
          "linear-gradient(328deg, rgb(118 122 9), rgb(228 243 115 / 78%))"; // Change color as needed

        // Prevent default behavior of arrow key press (scrolling the page)
        event.preventDefault();
      } else if (event.key === "ArrowUp") {
        // Move to the previous item
        if (
          currentMatchIndex !== -1 &&
          matchingItems.length > 0 &&
          !isNaN(currentMatchIndex)
        ) {
          matchingItems[currentMatchIndex].style.background =
            "linear-gradient(to bottom,rgb(164 155 155), rgb(50 43 43) 100%)"; // Restore previous color
          currentMatchIndex =
            (currentMatchIndex - 1 + matchingItems.length) %
            matchingItems.length;
          matchingItems[currentMatchIndex].scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          matchingItems[currentMatchIndex].style.background =
            "linear-gradient(328deg, rgb(118 122 9), rgb(228 243 115 / 78%))"; // Apply focus style
        }
        // currentMatchIndex =
        //   (currentMatchIndex - 1 + matchingItems.length) % matchingItems.length;
        // matchingItems[currentMatchIndex].scrollIntoView({
        //   behavior: "smooth",
        //   block: "center",
        // });
        // matchingItems[currentMatchIndex].style.background =
        //   "linear-gradient(328deg, rgb(118 122 9), rgb(228 243 115 / 78%))"; // Apply focus style
        event.preventDefault(); // Prevent default scrolling behavior
      } else if (event.key === "Enter") {
        // Activate or select the focused item

        if (currentMatchIndex !== -1) {
          // Simulate a click event to activate/select the item
          matchingItems[currentMatchIndex].click();
        }
        event.preventDefault(); // Prevent form submission or other default behavior
      }
    }
  });

  function playNextVideo() {
    if (selectedIndex !== null && selectedIndex < playlistItems.length - 1) {
      playVideo(selectedIndex + 1);
    } else {
      //console.log("Reached the end of the playlist");
    }
  }

  function checkAdTime() {
    if (adConfig.length > 0) {
      //console.log("vvvhhhh" + adConfig.length);
      if (!adPlaying && Array.isArray(adConfig)) {
        adConfig.forEach((ad, index) => {
          //console.log("ddddddddd", ad);
          if (
            ad &&
            typeof ad.time === "number" &&
            typeof ad.src === "string" &&
            player.currentTime() > ad.time
          ) {
            mainVideoTime = player.currentTime();
            //console.log("inside ad baby");
            player.pause();
            skipconfig.push(ad.skip);

            playAd(ad.src, ad.skip, ad.id);

            //console.log("ad.skip......", skipconfig);
            playedAds.add(ad.src);
            adConfig.splice(index, 1);
          }
        });
      }
    } else {
      player.off("timeupdate", checkAdTime);
    }
  }

  function playAd(adSrc, skiptime, adId) {
    currentAdId = adId;

    player.el().classList.add("vjs-ad-playing");
    //console.log("playing_ad_src_baby", adSrc);
    adPlaying = true;
    hidePlaylist();
    player.currentTime(0);
    // player.src({ src: adSrc, type: getVideoType(adSrc) });
    // player.play();
    // // player.controlBar.show();
    // player.one("loadedmetadata", () => {
    //   player.on("timeupdate", comeskip);
    //   player.off("timeupdate", checkPosterAd);
    // });

    player.ready(function() {
      var bigPlayButton = player.getChild("bigPlayButton");
      if (bigPlayButton) {
        bigPlayButton.hide();
      }
      player.currentTime(0);
      player.src({ src: adSrc, type: getVideoType(adSrc) });

      // Listen for 'loadedmetadata' to ensure the new video source is fully loaded
      player.one("loadedmetadata", () => {
        player.play(); // Start playback only after the metadata is loaded
        var bigPlayButton = player.getChild("bigPlayButton");
        if (bigPlayButton) {
          bigPlayButton.hide();
        }
        // alert("adplay");
        // alert("playAD_src: " + player.currentSrc()); // Log current source

        // Attach event listeners after the video is ready
        player.on("timeupdate", comeskip);
        player.off("timeupdate", checkPosterAd);
      });
    });

    if (posterads) {
      hidePosterAd();
    } // Hide poster ad when ad starts playing

    // const progressControl = player.controlBar.getChild("progressControl");
    // if (progressControl) {
    //   progressControl.disable();
    // }

    // Add adTextDiv to the control bar
    const adTextDiv = player.controlBar.addChild("component", {}, 1);
    adTextDiv.el().innerHTML =
      '<div id="ad-text" style="display:none; margin-top:12px; background: linear-gradient(to right, rgb(222 218 172), rgb(186 166 43)); -webkit-background-clip: text; -webkit-text-fill-color: white; font-size: 16px; padding-left: 10px;">Advertisement</div>';
    document.getElementById("ad-text").style.display = "block";

    // skipButton.style.display = "block";
    turnOffUserInactiveListener();
    player.off("ended", playNextVideo);
    player.off("timeupdate", checkAdTime);
    player.one("ended", onAdEnded);
    const adplaying = new CustomEvent("adplaying", {
      detail: {
        id: adId,
      },
    });

    player.el().dispatchEvent(adplaying);
  }

  function comeskip() {
    let a = skipconfig[0];
    //console.log("appu", a);
    if (skipconfig[0] !== undefined && player.duration() - a > 5) {
      //console.log("baby inside come skip");
      let skipenable = 5;
      let previousTime = Math.floor(player.currentTime()); // Initialize with the player's current time in seconds

      function onTimeUpdate() {
        //console.log("baby inside skip timer");

        const currentTime = Math.floor(player.currentTime()); // Get the current time in seconds

        // Check if one second has passed since the last update
        if (currentTime !== previousTime) {
          skipContainer.style.display = "flex";

          previousTime = currentTime;

          skipThumbnailImg.src = playlistItems[selectedIndex].poster;
          skipButton.innerHTML = `${skipenable}`;
          skipenable -= 1;
          if (skipenable < 0) {
            skipContainer.disabled = false;
            skipContainer.removeChild(skipThumbnail);
            skipContainer.classList.add("skip-ad-container-after");
            skipButton.style.maxWidth = "100%";
            skipButton.addEventListener("click", skipAd);
            skipButton.innerHTML = `
            <span class="skip-ad-text">Skip Ad</span>
                <span class="skip-ad-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="white">
                        <path fill-rule="white" clip-rule="evenodd" d="M2.538 4.113a1 1 0 0 1 1.035.068l10 7a1 1 0 0 1 0 1.638l-10 7A1 1 0 0 1 2 19V5a1 1 0 0 1 .538-.887ZM16 5.8A1.8 1.8 0 0 1 17.8 4h1.4A1.8 1.8 0 0 1 21 5.8v12.4a1.8 1.8 0 0 1-1.8 1.8h-1.4a1.8 1.8 0 0 1-1.8-1.8V5.8Z" fill="white"/>
                    </svg>
                </span>`;
            // Remove the event listener once the countdown reaches 0
            player.off("timeupdate", onTimeUpdate);
          }
        }
      }

      // Check the current time to start the countdown
      if (player.currentTime() > skipconfig[0]) {
        skipButton.disabled = true; // Disable initially

        // Start or resume the countdown timer
        // onTimeUpdate();
        player.on("timeupdate", onTimeUpdate);

        player.off("timeupdate", comeskip); // Remove comeskip as listener
        skipconfig.shift(); // Remove processed item from skipconfig array
      }
    } else {
      player.off("timeupdate", comeskip);
      skipconfig.shift();
    }
  }

  function onAdEnded() {
    skipContainer.appendChild(skipThumbnail);
    skipContainer.style.display = "none";
    skipContainer.classList.remove("skip-ad-container-after");
    skipButton.style.maxWidth = "30%";
    adPlaying = false;

    const adTextDiv = document.getElementById("ad-text");
    if (adTextDiv) {
      adTextDiv.style.display = "none";
    }

    if (posterads) {
      showPosterAd();
    } else {
      player.on("timeupdate", checkPosterAd);
    }
    player.el().classList.remove("vjs-ad-playing");
    player.off("timeupdate", comeskip);

    const adended = new CustomEvent("adended", {
      detail: {
        id: currentAdId, // Use the stored ad ID
      },
    });
    player.el().dispatchEvent(adended);
    resumevideo(selectedIndex);
  }

  function resumevideo(index, resumetime) {
    if (adConfig.length > 0) {
      player.on("timeupdate", checkAdTime);
    }
    // console.log("player123", player);
    player.ready(function() {
      // console.log("player1234", player);
      // console.log(
      //   "player1234keySystems",
      //   playlistItems[index].sources[0].keySystems
      // );

      player.currentTime(0);
      player.src({
        src: playlistItems[index].sources[0].src,
        type: getVideoType(playlistItems[index].sources[0].src),
        keySystems: playlistItems[index].sources[0].keySystems,
      });

      // Listen for 'loadedmetadata' to ensure the new video source is fully loaded
      // player.one("loadedmetadata", () => {
      //   console.log("player12345", player);

      //   player.play(); // Start playback only after the metadata is loaded

      //   // alert("adplay");
      //   console.log("playAD_src: " + player.currentSrc()); // Log current source

      //   // Attach event listeners after the video is ready
      //   // player.on("timeupdate", comeskip);
      //   // player.off("timeupdate", checkPosterAd);
      // });
    });

    // player.src({
    //   src: playlistItems[index].sources[0].src,
    //   type: getVideoType(playlistItems[index].sources[0].src),
    // });

    // console.log("mainVideoTime" + mainVideoTime);

    player.currentTime(mainVideoTime);
    player.play();
    // player.on("ended", playNextVideo);
    turnOnUserInactiveListener();

    player.one("loadedmetadata", () => {
      showPlaylist();
    });
  }

  function hidePlaylist() {
    playlistButton.style.display = "none";
    playerTitle.style.display = "none";
    playlistContainer.style.display = "none";
  }

  function showPlaylist() {
    playlistButton.style.display = "flex";
    playerTitle.style.display = "flex";
  }

  function skipAd() {
    player.off("ended", onAdEnded);
    onAdEnded();
  }

  function highlightSelectedItem(index) {
    const items = playlistContainer.querySelectorAll(".vjs-playlist-item");
    items.forEach((item, i) => {
      if (i === index) {
        // alert(i);
        item.classList.add("selected");
        // Update the player title with the selected episode's title
        const selectedItem = playlistItems[index];
        playerTitle.textContent = `${selectedItem?.title}/${selectedItem?.logicalChannelNumber}`;
      } else {
        item.classList.remove("selected");
      }
    });
  }

  function checkPosterAd() {
    // console.log("hi_babbyyyyyyyyyyyyyyyyyyy");
    if (
      playlistItems[selectedIndex].PosteradConfig &&
      playlistItems[selectedIndex].PosteradConfig.length > 0
    ) {
      if (
        player.currentTime() >=
        playlistItems[selectedIndex].PosteradConfig[0].time
      ) {
        // console.log("hi_babbyyyyyyyyyyyyyyyyyyy_1");

        let src = playlistItems[selectedIndex].PosteradConfig[0].src;
        let close_time =
          playlistItems[selectedIndex].PosteradConfig[0].closeButtonInMilisec;
        let adId = playlistItems[selectedIndex].PosteradConfig[0].id;

        displayPosterAd(src, close_time, adId);
      }
    } else {
      player.off("timeupdate", checkPosterAd);
    }
  }

  function displayPosterAd(src, time, adId) {
    // alert("display_posterad");
    // console.log("hi_babbyyyyyyyyyyyyyyyyyyy_2");

    posterads = true;

    posteradimg.src = src;
    postercloseButton.style.display = "none";

    if (adPlaying) {
      posteradContainer.style.display = "none";
    } else {
      posteradContainer.style.display = "block";
      const timeout = setTimeout(() => {
        postercloseButton.style.display = "block";
      }, time);

      postercloseButton.onclick = function() {
        posterclosebtn = true;
        posterads = false;
        posteradContainer.style.display = "none";
        // playlistItems[selectedIndex].PosteradConfig = null;
        playlistItems[selectedIndex].PosteradConfig.splice(0, 1);
      };
    }
    player.off("timeupdate", checkPosterAd);
    const posterAdplaying = new CustomEvent("posterAdplaying", {
      detail: { id: adId },
    });

    player.el().dispatchEvent(posterAdplaying);
  }

  function hidePosterAd() {
    //console.log("hideposterad", posterads);

    if (posterads) {
      const posteradContainer = player
        .el()
        .querySelector(".vjs-posterAd-container");
      if (posteradContainer) {
        posteradContainer.style.display = "none";
      }
    }
  }
  function showPosterAd() {
    const posteradContainer = player
      .el()
      .querySelector(".vjs-posterAd-container");
    if (posteradContainer) {
      posteradContainer.style.display = "block";
    }
  }

  player.getCurrentPlaylistItem = function() {
    if (selectedIndex !== null && playlistItems[selectedIndex]) {
      return playlistItems[selectedIndex];
    }
    return null;
  };
  function errordisplay() {
    if (adPlaying) {
      onAdEnded();
    } else {
      var modalDialogs = document.querySelectorAll(
        " .video-js .vjs-modal-dialog"
      );
      modalDialogs.forEach(function(dialog) {
        dialog.style.position = "absolute";
        dialog.style.top = "0";
        dialog.style.left = "0";
        dialog.style.width = "100%";
        dialog.style.height = "100%";
        dialog.style.zIndex = "1";
      });
    }
  }
  player.hi = function() {
    return selectedIndex;
  };
  function goBack() {
    window.history.back();
  }
  // Example of turning off the event listener
  function turnOffUserInactiveListener() {
    player.off("userinactive", handleUserInactive);
  }

  // Example of turning on the event listener
  function turnOnUserInactiveListener() {
    player.on("userinactive", handleUserInactive);
  }
};

videojs.registerPlugin("playlistLivePlugin", playlistLivePlugin);
