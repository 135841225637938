import { all, take, put, call } from "redux-saga/effects";
import ACTION_TYPES from "./actionTypes";
import * as ACTION from "./actions";
import * as DAS_API from "./../../services/datAnalytics/dataAnalytics";
// import * as PROFILE_ACION from "../ProfileDetails/actions";
import * as AUTHENTICATION_ACTIONS from "../../Redux/authentication/actions";
import * as DATA_ANALYTICS from "../../Redux/DATA-analytics/actions.js";

// import { Alert } from "react-native";
const {
  CONFIGURATION_START,
  SENDING_DATA_TO_DAS_START,
  FETCING_DATA_FOR_CONTINUE_WATCHING_START,
  FETCING_DATA_FOR_WATCHED_HISTORY_START,
  REMOVE_CONTINUE_WATCHING_HISTORY_START,
  REMOVE_WATCHES_HISTORY_START,
  VIEW_ANALYTICS_STATUS_START,
} = ACTION_TYPES;

function* ConfigureForDasSaga(payload) {
  try {
    const { data } = yield call(DAS_API.getDasconfigurationDataAsync);
    // console.log("default platform data       ", JSON.stringify(payload));

    if (data.status == true) {
      yield put(ACTION.configurationSucess(data));
      // yield put(
      //   DATA_ANALYTICS.fetchingDataForContinueWatchingStart({
      //     dataForFetchContinueWatching: {
      //       platformId: payload?.platformId,
      //       profileId: 1,
      //       userId: payload?.userId,
      //     },
      //     ServerIp: data.data[0].analyticsIp,
      //   })
      // );
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.configurationFailed({ message }));
  }
}

function* sendingDataToDasSaga(payload) {
  // console.log("pragathi" + JSON.stringify(payload));
  try {
    const { data } = yield call(DAS_API.getSendingDataToDasAsynce, payload);
    // console.log("Data Analytics  data       ", JSON.stringify(data));

    if (data.status == true) {
      yield put(ACTION.sendingDataToDasSuccess(data));
      // console.log("cleared");
      let dataObject = JSON.parse(localStorage.getItem("MovieDataToLocal"));

      // alert("in saga while removing" + JSON.stringify(dataObject));

      localStorage.removeItem("MovieDataToLocal");
    }
  } catch ({ message }) {
    yield put(ACTION.sendingDataToDasFailed({ message }));
  }
}

function* fetchingDataForContinueWatchingSaga(payload) {
  try {
    const { data } = yield call(
      DAS_API.fetchingDataForContinueWatchingDataAsync,
      payload
    );

    // console.log("default platform data       ", JSON.stringify(data));
    // console.log(
    //   "default platform data       ",
    //   JSON.stringify(payload.dataForFetchContinueWatching.userId)
    // );
    if (data.status == true) {
      // yield put(
      //   ACTION.fetchingDataForWatchesHistoryStart({
      //     dataForFetchWatchesHistory: {
      //       platformId: 1,
      //       profileId: 1,
      //       userId: payload.dataForFetchContinueWatching.userId,
      //     },
      //     ServerIp: payload?.ServerIp,
      //   })
      // );

      yield put(ACTION.fetchingDataForContinueWatchingSuccess(data));
    }
  } catch ({ message }) {
    yield put(ACTION.fetchingDataForContinueWatchingFailed({ message }));
  }
}
function* fetchingDataForWatchesHistorySaga(payload) {
  try {
    const { data } = yield call(
      DAS_API.fetchingDataForWatchesHistoryDataAsync,
      payload
    );

    if (data.status == true) {
      yield put(ACTION.fetchingDataForWatchesHistorySuccess(data));
    }
  } catch ({ message }) {
    yield put(ACTION.fetchingDataForWatchesHistoryFailed({ message }));
  }
}

// /remove/continueWatching
function* removeContinueWatchingSaga(payload) {
  try {
    const { data } = yield call(
      DAS_API.removeContinueWatchingDataAsync,
      payload
    );
    // console.log("default platform data       ", JSON.stringify(data));
    yield put(
      ACTION.fetchingDataForContinueWatchingStart({
        dataForFetchContinueWatching: {
          platformId: 1,
          profileId: 1,
          userId: payload.dataForRemoveContinueWatching.userId,
        },
        ServerIp: payload?.ServerIp,
      })
    );
    if (data.status == true) {
      // console.log(
      //   "removeContinueWatchingSuccess       ",
      //   JSON.stringify(payload)
      // );

      yield put(ACTION.removeContinueWatchingSuccess(data));
      yield put(
        ACTION.fetchingDataForContinueWatchingStart({
          dataForFetchContinueWatching: {
            platformId: 1,
            profileId: 1,
            userId: payload.dataForRemoveContinueWatching.userId,
          },
          ServerIp: payload?.ServerIp,
        })
      );
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(
      ACTION.fetchingDataForContinueWatchingStart({
        dataForFetchContinueWatching: {
          platformId: 1,
          profileId: 1,
          userId: payload.dataForRemoveContinueWatching.userId,
        },
        ServerIp: payload?.ServerIp,
      })
    );
    yield put(ACTION.removeContinueWatchingFailed({ message }));
  }
}
// /remove/WatchesHistory

function* removeWatchesHistorySaga(payload) {
  try {
    // console.log("payloadarunnnnn" + JSON.stringify(payload));
    const { data } = yield call(DAS_API.removeWatchesHistoryDataAsync, payload);
    // console.log("default platform data       ", JSON.stringify(data));

    if (data.status == true) {
      yield put(ACTION.removeWatchesHistorySuccess(data));
      yield put(
        ACTION.fetchingDataForWatchesHistoryStart({
          dataForFetchWatchesHistory: {
            platformId: 1,
            profileId: 1,
            userId: payload.dataForRemoveContinueWatching.userId,
          },
          ServerIp: payload?.ServerIp,
        })
      );
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(
      ACTION.fetchingDataForWatchesHistoryStart({
        dataForFetchWatchesHistory: {
          platformId: 1,
          profileId: 1,
          userId: payload.dataForRemoveContinueWatching.userId,
        },
        ServerIp: payload?.ServerIp,
      })
    );
    yield put(ACTION.removeWatchesHistoryFailed({ message }));
  }
}

// /viewAnalyticsStatus
function* viewAnalyticsStatusSaga(payload) {
  try {
    const { data } = yield call(DAS_API.viewAnalyticsStatusDataAsync, payload);
    // console.log("default platform data  jcnsjcj     ", JSON.stringify(data));

    if (data.status == true) {
      yield put(ACTION.viewAnalyticsStatusSuccess(data));
      yield put(
        AUTHENTICATION_ACTIONS.userDetailsAction({ id: data.data?.id })
      );
    }

    // RootNavigation.navigate("packageDetails");
  } catch ({ message }) {
    yield put(ACTION.viewAnalyticsStatusFailed({ message }));
  }
}

function* getConfigureForDasWatcher() {
  while (true) {
    //console.log("watcher saga is called");

    const { payload } = yield take(CONFIGURATION_START);

    yield call(ConfigureForDasSaga, payload);
  }
}

function* getSendingDataDasWatcher() {
  while (true) {
    // console.log("watcher saga is called");
    const { payload } = yield take(SENDING_DATA_TO_DAS_START);
    yield call(sendingDataToDasSaga, payload);
  }
}

function* fetchingDataForContinueWatchingWatcher() {
  while (true) {
    //console.log("watcher saga is called");

    const { payload } = yield take(FETCING_DATA_FOR_CONTINUE_WATCHING_START);
    yield call(fetchingDataForContinueWatchingSaga, payload);
  }
}
function* fetchingDataForWatchesHistoryWatcher() {
  while (true) {
    //console.log("watcher saga is called");

    const { payload } = yield take(FETCING_DATA_FOR_WATCHED_HISTORY_START);
    yield call(fetchingDataForWatchesHistorySaga, payload);
  }
}
function* removeContinueWatchingWatcher() {
  while (true) {
    const { payload } = yield take(REMOVE_CONTINUE_WATCHING_HISTORY_START);
    yield call(removeContinueWatchingSaga, payload);
  }
}

function* removeWatchesHistoryWatcher() {
  while (true) {
    const { payload } = yield take(REMOVE_WATCHES_HISTORY_START);
    yield call(removeWatchesHistorySaga, payload);
  }
}

function* viewAnalyticsStatusWatcher() {
  while (true) {
    const { payload } = yield take(VIEW_ANALYTICS_STATUS_START);
    yield call(viewAnalyticsStatusSaga, payload);
  }
}
export default function*() {
  yield all([
    getConfigureForDasWatcher(),
    getSendingDataDasWatcher(),
    fetchingDataForContinueWatchingWatcher(),
    fetchingDataForWatchesHistoryWatcher(),
    removeContinueWatchingWatcher(),
    removeWatchesHistoryWatcher(),
    viewAnalyticsStatusWatcher(),
  ]);
}
