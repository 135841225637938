/** @format */

import ACTION_TYPES from "./action.types";

const {
  GET_ALL_LIVE_ASSETS_START,
  GET_ALL_LIVE_ASSETS_SUCCESS,
  GET_ALL_LIVE_ASSETS_FAILED,

  GET_ALL_LIVE_ASSETS_BASED_ON_KEY_START,
  GET_ALL_LIVE_ASSETS_BASED_ON_KEY_SUCCESS,
  GET_ALL_LIVE_ASSETS_BASED_ON_KEY_FAILED,

  GET_ALL_LIVE_ASSETS_FOR_LISTING,
  GET_LIVE_ASSETS_OFFSET_WISE,

  GET_GENERAL_DETAILS_START,
  GET_GENERAL_DETAILS_SUCCESS,
  GET_GENERAL_DETAILS_FAILED,
} = ACTION_TYPES;

const initialState = {
  liveLoading: false,
  liveAssetDetails: null,
  genres: null,
  allLiveAssetForListing: null,
  allAssetIds: null,
  liveAssetsOffsetWise: null,

  AssetDetailsBaedOnkey: null,
  GenreDetailsBaedOnkey: null,
  AllGenreDetailsBaedOnkey: null,

  GenreDetailsToDisplayBasedOnKey: null,
  genreIdsBasedOnSortOrder: null,
  generalDetails: null,
  generalAdsDetails: null,
  generalGenreDetails: null,
  generalLanguageDetails: null,
  generalTagDetails: null,
  generalVideoAdsDetails: null,
  genreDetailsLength: null,
};

export default (state = initialState, { type, payload }) => {
  const isLoading = false;
  const errors = null;

  switch (type) {
    case GET_ALL_LIVE_ASSETS_START:
      // alert(JSON.stringify(payload));
      return {
        ...state,
        liveLoading: true,
      };

    case GET_ALL_LIVE_ASSETS_SUCCESS:
      return {
        ...state,
        liveLoading: false,
        // liveAssetDetails: payload.data?.[1].AssetDetails?.assetDetailsBasedOnId,
        // liveAssetDetails: [],
        // allAssetIds: payload.data?.[1].AssetDetails?.assetIdsForLCNArray,
        genres: payload?.data?.[0]?.genreDetails,

        errors: payload.message,
      };

    case GET_ALL_LIVE_ASSETS_FAILED:
      return {
        ...state,

        liveLoading: false,
        errors: payload?.message,
      };

    case GET_ALL_LIVE_ASSETS_BASED_ON_KEY_START:
      return {
        ...state,
      };

    case GET_ALL_LIVE_ASSETS_BASED_ON_KEY_SUCCESS:
      let minimumAssetsInGenre = [];
      payload?.data?.[0]?.genreIdsBasedOnSortOrder?.map((genreId) => {
        let GenreData = payload?.data?.[0]?.genreDetails[`"${genreId}"`];
        let genreAssetAssociation = [];
        for (let i = 0; i < 10; i++) {
          if (GenreData.genreassetassociation?.length >= i + 1) {
            genreAssetAssociation?.push(GenreData.genreassetassociation?.[i]);
          }
        }
        GenreData = {
          ...GenreData,
          genreassetassociation: genreAssetAssociation,
        };

        // minimumAssetsInGenre[`"${genreId}"`] = GenreData;
        minimumAssetsInGenre?.push(GenreData);
      });
      const minimumAssetsInGenreBasedOnKey = minimumAssetsInGenre.reduce(
        (acc, genre) => {
          acc[`"${genre.id}"`] = genre;
          return acc;
        },
        {}
      );
      // console.log(
      //   "MINIMUM DATAAAAAAAAAAAA   " +
      //     JSON.stringify(minimumAssetsInGenreBasedOnKey)
      // );
      return {
        ...state,
        allAssetIds: payload.data?.[1].AssetDetails?.assetIdsForLCNArray,
        liveAssetDetails: payload.data?.[1].AssetDetails?.assetDetailsBasedOnId,

        AssetDetailsBaedOnkey:
          payload.data?.[1].AssetDetails?.assetDetailsBasedOnId,
        GenreDetailsBaedOnkey: minimumAssetsInGenreBasedOnKey,
        AllGenreDetailsBaedOnkey: payload?.data?.[0]?.genreDetails,
        // GenreDetailsToDisplayBasedOnKey,

        genreIdsBasedOnSortOrder: payload?.data?.[0]?.genreIdsBasedOnSortOrder,
        errors: payload.message,
        genreDetailsLength: payload?.assetlength,
      };

    case GET_ALL_LIVE_ASSETS_BASED_ON_KEY_FAILED:
      return {
        ...state,

        errors: payload?.message,
      };
    case GET_LIVE_ASSETS_OFFSET_WISE:
      let GenresAfterOffset = state.GenreDetailsBaedOnkey;
      // let indexOfSelectedGenre = state.GenreDetailsBaedOnkey.findIndex(
      //   (element) => {
      //     return element.id === payload?.genreId;
      //   }
      // );

      let SelectedAssetAfterOffset =
        state.GenreDetailsBaedOnkey[`"${payload.genreId}"`];
      let selectedGenreDataWithAllAssets =
        state.AllGenreDetailsBaedOnkey[`"${payload.genreId}"`];

      console.log("##### payload.offset" + Number(payload.offset));

      for (
        let i = Number(payload.offset);
        i < Number(payload.offset) + 5;
        i++
      ) {
        console.log("@@@@@@@@@    " + i);
        if (
          selectedGenreDataWithAllAssets?.genreassetassociation[i] !=
            undefined &&
          selectedGenreDataWithAllAssets?.genreassetassociation[i] != null
        ) {
          SelectedAssetAfterOffset?.genreassetassociation?.push(
            selectedGenreDataWithAllAssets?.genreassetassociation[i]
          );
        }
      }

      GenresAfterOffset = {
        ...GenresAfterOffset,
        [`"${payload.genreId}"`]: SelectedAssetAfterOffset,
      };

      console.log("##### SelectedAssetAfterOffset" + SelectedAssetAfterOffset);

      // GenresAfterOffset.splice(indexOfSelectedGenre, 1);
      // GenresAfterOffset.splice(
      //   indexOfSelectedGenre,
      //   0,
      //   SelectedAssetAfterOffset[0]
      // );
      // console.log("AFTER OFFSET " + JSON.stringify(GenresAfterOffset[`"${payload.genreId}"`]));

      console.log("##### GenresAfterOffset" + GenresAfterOffset);

      return {
        ...state,
        GenreDetailsBaedOnkey: GenresAfterOffset,
      };

    case GET_ALL_LIVE_ASSETS_FOR_LISTING:
      return {
        ...state,

        allLiveAssetForListing: payload,
      };
    case GET_GENERAL_DETAILS_START:
      return {
        ...state,
      };
    case GET_GENERAL_DETAILS_SUCCESS:
      return {
        ...state,
        generalDetails: payload?.data,
        generalAdsDetails: payload?.data?.adsDetails,
        generalGenreDetails: payload?.data?.genreDetails,
        generalLanguageDetails: payload?.data?.languageDetails,
        generalTagDetails: payload?.data?.tagDetails,
        generalVideoAdsDetails: payload?.data?.videoAdsDetails,
      };
    case GET_GENERAL_DETAILS_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};
