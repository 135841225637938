import { Alert } from "react-bootstrap";
import ACTION_TYPES from "./actionTypes";
const {
  CONFIGURATION_START,
  CONFIGURATION_SUCCESS,
  CONFIGURATION_FAILED,

  SENDING_DATA_TO_DAS_START,
  SENDING_DATA_TO_DAS_SUCCESS,
  SENDING_DATA_TO_DAS_FAILED,

  // /continue/watching
  FETCING_DATA_FOR_CONTINUE_WATCHING_START,
  FETCING_DATA_FOR_CONTINUE_WATCHING_SUCCESS,
  FETCING_DATA_FOR_CONTINUE_WATCHING_FAILED,
  FETCHING_PLAYER_DATA_SUCCESS,

  // /watchesHistory
  FETCING_DATA_FOR_WATCHED_HISTORY_START,
  FETCING_DATA_FOR_WATCHED_HISTORY_SUCCESS,
  FETCING_DATA_FOR_WATCHED_HISTORY_FAILED,

  // /remove/continueWatching
  REMOVE_CONTINUE_WATCHING_HISTORY_START,
  REMOVE_CONTINUE_WATCHING_HISTORY_SUCCESS,
  REMOVE_CONTINUE_WATCHING_HISTORY_FAILED,

  // viewAnalyticsStatus
  VIEW_ANALYTICS_STATUS_START,
  VIEW_ANALYTICS_STATUS_SUCCESS,
  VIEW_ANALYTICS_STATUS_FAILED,

  CLEAR_CONTINUE_WATCHING,
  CLEAR_WATCHED_HISTORY,
} = ACTION_TYPES;

const initialState = {
  // dataAnalytics: {},
  errors: "",
  configuresForDas: [],
  sendingDatatoDas: [],
  continueWatching: [],
  watchedHistory: [],
  viewAnalyticsStatus: [],
};
function durationToSeconds(duration) {
  const [hours, minutes, seconds] = duration.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

function secondsToDuration(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const sec = seconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
}
export default (state = initialState, { type, payload }) => {
  let errors = "";
  switch (type) {
    case CONFIGURATION_START:
      return {
        ...state,

        errors,
      };
    case CONFIGURATION_SUCCESS:
      // alert(JSON.stringify(payload?.data[0]));
      return {
        ...state,
        configuresForDas: payload?.data[0],
        errors,
      };

    case CONFIGURATION_FAILED:
      return {
        ...state,
        errors: payload.message,
      };

    case SENDING_DATA_TO_DAS_START:
      // alert("Sending Data start");
      return {
        ...state,

        errors,
      };
    case SENDING_DATA_TO_DAS_SUCCESS:
      return {
        ...state,
        // sendingDatatoDas: payload,
        errors,
      };

    case SENDING_DATA_TO_DAS_FAILED:
      return {
        ...state,
        errors: payload.message,
      };

    // /continue/watching

    case FETCING_DATA_FOR_CONTINUE_WATCHING_START:
      // alert(" continue star");

      return {
        ...state,

        errors,
      };

    // /continue/watching
    // /continue/watching

    case FETCING_DATA_FOR_CONTINUE_WATCHING_SUCCESS:
      // alert("continue success");

      const apiData = payload?.data;
      // console.log(
      //   "Fetching continueWatching apiData" + JSON.stringify(apiData)
      // );

      if (Array.isArray(apiData) && apiData?.length > 0) {
        let mergedArray = [
          ...state?.continueWatching?.filter(
            (existingItem) =>
              !apiData.some(
                (newItem) =>
                  newItem.assetId === existingItem.assetId &&
                  newItem.categoryId === existingItem.categoryId &&
                  newItem.seasonId === existingItem.seasonId &&
                  newItem.episodeId === existingItem.episodeId
              )
          ),
          ...apiData,
        ];

        if (state.continueWatching && state.continueWatching.length > 0) {
          mergedArray = mergedArray.map((item) => {
            const existingItem = state.continueWatching.find(
              (existingItem) =>
                existingItem.isVideoEnded == 0 &&
                item.assetId === existingItem.assetId &&
                item.categoryId === existingItem.categoryId &&
                item.seasonId === existingItem.seasonId &&
                item.episodeId === existingItem.episodeId
            );
            if (existingItem) {
              const maxDuration = Math.max(
                durationToSeconds(item.duration),
                durationToSeconds(existingItem.duration)
              );
              return {
                ...item,
                duration: secondsToDuration(maxDuration),
              };
            } else {
              return item;
            }
          });
        }

        // Filter out items that are already in watchedHistory
        const filteredArray = mergedArray.filter(
          (item) =>
            !(
              Array.isArray(state.watchedHistory) &&
              state.watchedHistory.some(
                (history) =>
                  history.assetId === item.assetId &&
                  history.categoryId === item.categoryId
              )
            )
        );

        // console.log("filteredArray" + JSON.stringify(filteredArray));
        // console.log("mergedArray" + JSON.stringify(mergedArray));
        return {
          ...state,
          continueWatching: filteredArray,
        };
      } else {
        return {
          ...state,
          continueWatching: state?.continueWatching,
        };
      }

    case FETCHING_PLAYER_DATA_SUCCESS:
      const updatedPlayerData = payload;
      // console.log("playerData" + JSON.stringify(updatedPlayerData));
      // console.log(
      //   "arun fetch continueWatching playerData" +
      //     JSON.stringify(updatedPlayerData)
      // );

      // Find the index of the existing item in the continueWatching array
      const existingIndex = state.continueWatching?.findIndex(
        (item) =>
          item.assetId === updatedPlayerData.assetId &&
          item.categoryId === updatedPlayerData.categoryId &&
          item.seasonId === updatedPlayerData.seasonId &&
          item.episodeId === updatedPlayerData.episodeId
      );

      // If the assetId, categoryId, seasonId, and episodeId exist in the continueWatching array,
      // remove the item from its current position
      if (existingIndex !== -1) {
        const updatedArray = [
          updatedPlayerData,
          ...state.continueWatching?.filter(
            (item, index) => index !== existingIndex
          ),
        ];
        return {
          ...state,
          continueWatching: updatedArray,
        };
      } else {
        // If the item is not found in the array, add it to the top
        const updatedArray = [updatedPlayerData, ...state.continueWatching];
        return {
          ...state,
          continueWatching: updatedArray,
        };
      }

    case FETCING_DATA_FOR_CONTINUE_WATCHING_FAILED:
      return {
        ...state,
        errors: payload.message,
      };

    // /watchesHistory

    case FETCING_DATA_FOR_WATCHED_HISTORY_START:
      // alert(" watched start");

      return {
        ...state,

        errors,
      };
    case FETCING_DATA_FOR_WATCHED_HISTORY_SUCCESS:
      // alert(" watched success");
      // console.log("ARUNVN123", JSON.stringify(payload?.data));
      // console.log("ARUNVN123", JSON.stringify(state?.continueWatching));

      // Check if payload.data is an array before proceeding
      const watchedHistoryData = Array.isArray(payload?.data)
        ? payload.data
        : [];

      return {
        ...state,
        watchedHistory: watchedHistoryData,

        // Only filter continueWatching if both arrays exist and are valid
        continueWatching:
          Array.isArray(state?.continueWatching) &&
          state?.continueWatching?.filter(
            (item) =>
              !watchedHistoryData.some(
                (history) =>
                  history?.assetId === item?.assetId &&
                  history?.categoryId === item?.categoryId
              )
          ),
      };

    case FETCING_DATA_FOR_WATCHED_HISTORY_FAILED:
      return {
        ...state,
        errors: payload.message,
      };
    // /remove/continueWatching

    case REMOVE_CONTINUE_WATCHING_HISTORY_START:
      const {
        assetId,
        categoryId,
        type,
      } = payload.dataForRemoveContinueWatching;
      let updatedContinueWatching;

      if (type === "All") {
        // If type is "All", set videoDeleted to 1 for all assets
        updatedContinueWatching = state.continueWatching.map((item) => ({
          ...item,
          videoDeleted: 1,
        }));
      } else if (type === "single" && categoryId && assetId) {
        // If type is "single" and categoryId and assetId are provided,
        // set videoDeleted to 1 for matching assetId and categoryId
        updatedContinueWatching = state.continueWatching.map((item) => {
          if (item.assetId === assetId && item.categoryId === categoryId) {
            return { ...item, videoDeleted: 1 };
          }
          return item;
        });
      } else {
        // Handle other cases if needed
      }

      return {
        ...state,
        continueWatching: updatedContinueWatching,
        errors: "",
      };

    case CLEAR_CONTINUE_WATCHING:
      return {
        ...state,
        continueWatching: [],
      };

    case CLEAR_WATCHED_HISTORY:
      return {
        ...state,
        watchedHistory: [],
      };

    case REMOVE_CONTINUE_WATCHING_HISTORY_SUCCESS:
      // alert(JSON.stringify(payload?.data[0]));
      return {
        ...state,
        errors,
      };

    case REMOVE_CONTINUE_WATCHING_HISTORY_FAILED:
      return {
        ...state,
        errors: payload.message,
      };

    // viewAnalyticsStatus
    case VIEW_ANALYTICS_STATUS_START:
      return {
        ...state,

        errors,
      };
    case VIEW_ANALYTICS_STATUS_SUCCESS:
      // alert(JSON.stringify(payload?.data[0]));
      return {
        ...state,
        viewAnalyticsStatus: payload?.data[0],
        errors,
      };

    case VIEW_ANALYTICS_STATUS_FAILED:
      return {
        ...state,
        errors: payload.message,
      };

    default:
      return state;
  }
};
