import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance, { encryptData } from "../../utils/axiosInstance";
var qs = require("qs");
export const liveDataAsync = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  try {
    // Encrypt the payload.id
    const ciphertext = encryptData(payload?.id);
    const urlSafeCiphertext = ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, "");

    // console.log("live assets parameters " + JSON.stringify(payload));

    const liveDataResponse = await axiosInstance().post(
      "/live/" + urlSafeCiphertext, // Use the encrypted id
      payload,
      {
        headers: ConstantHeaders, // Use the headers obtained from createHeaders
      }
    );

    return liveDataResponse.data;
  } catch (error) {
    return Promise.reject(error.response?.data || error);
  }
};
