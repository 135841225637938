/** @format */

import axiosInstance, { encryptData } from "../../utils/axiosInstance";
var qs = require("qs");

export const getDeviceListAsync = async (payload) => {
  try {
    // Encrypt the userId

    // console.log("payloadpayloadpayloadpayload" + JSON.stringify(payload));
    var ciphertext = encryptData(payload?.userId);

    const urlSafeCiphertext = ciphertext
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/, "");

    const response = await axiosInstance().post(
      `/devices/newV1/${urlSafeCiphertext}`, // Use the encrypted userId

      // "/devices/newV1/" + urlSafeCiphertext,

      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const replaceDeviceAsync = async (payload) => {
  try {
    const response = await axiosInstance().post("/replace/deviceV1", payload);
    return response;
  } catch (error) {
    throw error;
  }
};
