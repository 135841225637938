/** @format */

import swal from "sweetalert";
import {
  HeadersAcessToken,
  HeadersAcessTokenForFormData,
} from "../../components/HeadersAcessToken";
import store from "./../../Redux/store.js";
import * as activeAccessTokenService from "./../activeAccessToken/activeAccessToken.js";
import * as AUTH_ACTION from "./../../Redux/authentication/actions.js";
import axiosInstance, { encryptData } from "../../utils/axiosInstance";

var qs = require("qs");

export const createProfileAsync = async (payload) => {
  try {
    const response = await axiosInstance().post(
      "/create/profile",
      qs.stringify(payload)
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const profileCreateAsync = async (payload) => {
  try {
    const formData = new FormData();
    formData.append("account_id", payload.account_id);
    formData.append("name", payload.name);
    formData.append("isForKids", payload.isForKids);
    formData.append("userId", payload.userId);
    formData.append("updatedBy", payload.updatedBy);
    if (
      payload.currentPackageId !== undefined &&
      payload.currentPackageId !== null
    ) {
      formData.append("currentPackageId", payload.currentPackageId);
    }
    formData.append("avatar", payload.profileUrl);

    const response = await axiosInstance().post("/create/profile", formData);
    return response;
  } catch (error) {
    swal("You have already reached maximum profile");
    throw error.response.data;
  }
};

export const getProfileListAsync = async (payload) => {
  try {
    const response = await axiosInstance().post(
      `/profile/${payload.userId}`,
      qs.stringify(payload)
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteProfileAsync = async (payload) => {
  try {
    const response = await axiosInstance().post(
      `/delete/profile/${payload.profileId}`,
      qs.stringify(payload),
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const editProfileAsync = async (payload) => {
  try {
    const response = await axiosInstance().post("/", qs.stringify(payload));
    return response.data;
  } catch (error) {
    // Handle error as needed
  }
};

export const getProfileAsync = async (payload) => {
  try {
    const response = await axiosInstance().post("/", qs.stringify(payload));
    return response.data;
  } catch (error) {
    // Handle error as needed
  }
};

export const profileUpdateAsync = async (payload) => {
  try {
    const formData = new FormData();
    formData.append("account_id", payload.account_id);
    formData.append("id", payload.id);
    formData.append("name", payload.name);
    formData.append("isForKids", payload.isForKids);
    formData.append("userId", payload.userId);
    formData.append("updatedBy", payload.updatedBy);
    formData.append("avatar", payload.profileUrl);

    const response = await axiosInstance().post(
      `/update/profile/${payload.id}`,
      formData
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const profileBlockAsync = async (payload) => {
  try {
    const response = await axiosInstance().post(
      `/block/profile/${payload.profileId}`,
      qs.stringify(payload)
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const profileUnBlockAsync = async (payload) => {
  try {
    const response = await axiosInstance().post(
      `/unblock/profile/${payload.profileId}`,
      qs.stringify(payload)
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const profileSendEmailAsync = async (payload) => {
  try {
    const response = await axiosInstance().post(
      "/secretKey/mail/profile",
      qs.stringify(payload)
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getProfilepicUrlAsync = async (payload) => {
  alert(JSON.stringify(payload));
  const formData = new FormData();
  var obj = {
    id: payload.id,
    updatedBy: payload.id,
  };
  var payload1 = encryptData(obj);

  formData.append("data", payload1);
  // formData.append("id", payload.id);
  // formData.append("updatedBy", payload.updatedBy);
  formData.append("profile_url", payload.profile_url);

  let access_data = store.getState();
  let access_token =
    access_data?.authenticationReducer?.loginDetails?.access_token;
  let refresh_token =
    access_data?.authenticationReducer?.loginDetails?.refresh_token;

  // console.log("formData", formData);

  try {
    const response = await axiosInstance().post(
      "/updateProfilePicV1",
      formData,
      {
        headers: HeadersAcessTokenForFormData(access_token),
      }
    );
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.auth === false
    ) {
      try {
        const newAccessTokenResponse = await activeAccessTokenService.activeAccessTokenAsync(
          {
            refreshToken: refresh_token,
          }
        );
        await store.dispatch(
          AUTH_ACTION.updateAccessToken(newAccessTokenResponse.data)
        );
        const newAccessToken = newAccessTokenResponse.data.accessToken;

        const response = await axiosInstance().post(
          "/updateProfilePicV1",
          formData,
          {
            headers: HeadersAcessTokenForFormData(newAccessToken),
          }
        );

        return response;
      } catch (error) {
        //alert("something went wrong while refreshing token");
        throw error;
      }
    } else {
      throw error.response.data;
    }
  }
};
