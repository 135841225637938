import { HeadersAcessToken } from "../../components/HeadersAcessToken";
import axiosInstance from "../../utils/axiosInstance";

var qs = require("qs");

export const socketStatusAPI = async (payload) => {
  const ConstantHeaders = HeadersAcessToken();

  // console.log("arun insertAssetData " + JSON.stringify(payload));
  return await axiosInstance()
    .post("/create/message", payload, {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      // console.log("arun responseData" + JSON.stringify(response.data));

      return response.data;
    })

    .catch((error) => {
      Promise.reject(error.response.data);
      // console.log("errorData" + JSON.stringify(response.data));
    });
};

export const getDasconfigurationDataAsync = async (payload) => {
  //console.log("live assets parameters " + JSON.stringify(payload));
  const ConstantHeaders = HeadersAcessToken();

  return await axiosInstance()
    .post("/das/configurations", qs.stringify(payload), {
      headers: ConstantHeaders, // Use the headers obtained from createHeaders
    })
    .then((response) => {
      // console.log("gdvgsa", response.data);
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};

export const getSendingDataToDasAsynce = async (payload) => {
  // console.log("poooojaaaaaaaaa " + JSON.stringify(payload));
  return await axiosInstance()
    .post(
      payload?.ServerIp + "/daswebapp/insert/assetData",
      qs.stringify(payload?.dataAnalyticsForAPI),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then((response) => {
      // console.log("sendinggggg", response.data);
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};

export const fetchingDataForContinueWatchingDataAsync = async (payload) => {
  // console.log("poooojaaaaaaaaa " + JSON.stringify(payload));
  return await axiosInstance()
    .post(
      payload?.ServerIp + "/daswebapp/continue/watching",
      qs.stringify(payload?.dataForFetchContinueWatching),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )

    .then((response) => {
      // console.log("sendinggggg", response.data);
      return response;
    })
    .catch((error) => {
      // console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};

export const fetchingDataForWatchesHistoryDataAsync = async (payload) => {
  // console.log("poooojaaaaaaaaa iiii" + JSON.stringify(payload));
  return await axiosInstance()
    .post(
      payload?.ServerIp + "/daswebapp/watchesHistory",
      qs.stringify(payload?.dataForFetchWatchesHistory),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then((response) => {
      // console.log("sendinggggg", response.data);
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};

export const removeContinueWatchingDataAsync = async (payload) => {
  // console.log("poooojaaaaaaaaa " + JSON.stringify(payload));
  return await axiosInstance()
    .post(
      payload?.ServerIp + "/daswebapp/remove/continueWatching",
      qs.stringify(payload?.dataForRemoveContinueWatching),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then((response) => {
      // console.log("sendinggggg", response.data);
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};

export const removeWatchesHistoryDataAsync = async (payload) => {
  // console.log("poooojaaaaaaaaa " + JSON.stringify(payload));
  return await axiosInstance()
    .post(
      payload?.ServerIp + "/daswebapp/remove/watchedHistory",
      qs.stringify(payload?.dataForRemoveContinueWatching),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then((response) => {
      // console.log("sendinggggg", response.data);
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};
export const viewAnalyticsStatusDataAsync = async (payload) => {
  try {
    const userContinueWatchingViewStatus = await axiosInstance().post(
      "update/viewAnalyticsStatusV1",
      qs.stringify(payload.dataForViewAnalyticsStatusStart)
    );
    return userContinueWatchingViewStatus;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getSendingDataOfVideoadsAsync = async (payload) => {
  // console.log("poooojaaaaaaaaa " + JSON.stringify(payload));
  return await axiosInstance()
    .post(
      payload?.ServerIp + "/daswebapp/insertVideoAdsData",
      qs.stringify(payload?.adsAnalyticsData),

      // "https://datago.dishhome.com.np/daswebapp/insertVideoAdsData",
      // qs.stringify(payload),
      {
        headers: {
          headers: HeadersAcessToken(),
        },
      }
    )
    .then((response) => {
      // console.log("sendinggggg", response.data);
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};

export const getSendingDataOfPosteradsAsync = async (payload) => {
  // console.log("poooojaaaaaaaaa " + JSON.stringify(payload));
  return await axiosInstance()
    .post(
      payload?.ServerIp + "/daswebapp/insertPosterAdsData",
      qs.stringify(payload?.adsAnalyticsData),

      // "https://datago.dishhome.com.np/daswebapp/insertPosterAdsData",
      // qs.stringify(payload),
      {
        headers: {
          headers: HeadersAcessToken(),
        },
      }
    )
    .then((response) => {
      // console.log("sendinggggg", response.data);
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};

export const getSendingDataOfGenreVideoAsync = async (payload) => {
  // console.log("poooojaaaaaaaaa " + JSON.stringify(payload));
  return await axiosInstance()
    .post(
      payload?.ServerIp + "/daswebapp/insertGenreVideoAdsData",
      qs.stringify(payload?.adsAnalyticsData),

      // "https://datago.dishhome.com.np/daswebapp/insertPosterAdsData",
      // qs.stringify(payload),
      {
        headers: {
          headers: HeadersAcessToken(),
        },
      }
    )
    .then((response) => {
      // console.log("sendinggggg", response.data);
      return response;
    })
    .catch((error) => {
      //console.log("error response " + error);
      Promise.reject(error.response.data);
    });
};
